<!--
  论文详情
-->
<template>
  <div class="resource_detail" v-loading="loading">
    <div class="detail_left">
      <div class="base_info module_box tw-mb-20px">
        <div class="module_box_header">
          <p>论文详情</p>
        </div>
        <div class="module_box_main">
          <div class="main_header">
            <div class="header_left">
              <h1>{{ detail.paperTitle }}</h1>
            </div>
          </div>
          <div class="main_content">
            <p class="description">{{ detail.paperAbstract ? detail.paperAbstract : '暂无描述' }}</p>
            <div class="keywords">
              <div class="keywords_label">
                关键字：
              </div>
              <div class="keywords_value">
                <span>
                  {{ detail.paperKey ? detail.paperKey.split('%').toString() : '' }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="other_info module_box tw-mb-20px">
        <div class="module_box_header">
          <p>详细信息</p>
        </div>
        <div class="module_box_main">
          <el-row>
            <el-col :span="12">
              <div class="text_item">
                <div class="item_label">
                  论文编号：
                </div>
                <div class="item_value">
                  {{ detail.paperNo }}
                </div>
              </div>
            </el-col>
            <el-col :span="12">
              <div class="text_item">
                <div class="item_label">
                  论文标题：
                </div>
                <div class="item_value">
                  {{ detail.paperTitle }}
                </div>
              </div>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <div class="text_item">
                <div class="item_label">
                  作者姓名：
                </div>
                <div class="item_value">
                  {{ detail.paperAuthor ? detail.paperAuthor.split('%').toString() : '' }}
                </div>
              </div>
            </el-col>
            <el-col :span="12">
              <div class="text_item">
                <div class="item_label">
                  作者单位：
                </div>
                <div class="item_value">
                  {{ detail.paperAuthorUnit ? detail.paperAuthorUnit.split('%').toString() : '' }}
                </div>
              </div>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <div class="text_item">
                <div class="item_label">
                  刊名：
                </div>
                <div class="item_value">
                  {{ detail.title }}
                </div>
              </div>
            </el-col>
            <el-col :span="12">
              <div class="text_item">
                <div class="item_label">
                  英文名：
                </div>
                <div class="item_value">
                  {{ detail.titleInEng }}
                </div>
              </div>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <div class="text_item">
                <div class="item_label">
                  年，卷(期)：
                </div>
                <div class="item_value">
                  {{ detail.year }}, {{ detail.volume }}({{ detail.phase }})
                </div>
              </div>
            </el-col>
            <el-col :span="12">
              <div class="text_item">
                <div class="item_label">
                  所属期刊栏⽬：
                </div>
                <div class="item_value">
                  {{ detail.column }}
                </div>
              </div>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <div class="text_item">
                <div class="item_label">
                  页码：
                </div>
                <div class="item_value">
                  {{ detail.paperPageRegion }}
                </div>
              </div>
            </el-col>
            <el-col :span="12">
              <div class="text_item">
                <div class="item_label">
                  页数：
                </div>
                <div class="item_value">
                  {{ detail.paperPage }}
                </div>
              </div>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <div class="text_item">
                <div class="item_label">
                  万方原文链接：
                </div>
                <div class="item_value">
                  <el-link
                    :href="detail.paperLink"
                    target="_blank">
                    {{ detail.paperLink }}
                  </el-link>
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
    <div class="detail_right">
      <div class="recommend_list module_box tw-mb-20px">
        <div class="module_box_header">
          <p>其他相关资源推荐</p>
        </div>
        <div class="module_box_main">
          <div
            class="recommend_item"
            v-for="item in requireRecommendList"
            :key="item.id"
            @click="onDetail(item)">
            <div class="item_header">
              <h1 :title="item.title">{{ item.paperTitle }}</h1>
            </div>
            <div class="item_footer">
              <p :title="`${item.paperAuthor.split('%').toString()}-${item.title}`">
                {{ item.paperAuthor.split('%').toString() }}-{{ item.title }}
              </p>
              <p>{{ item.year }}, {{ item.volume }}({{ item.phase }})</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as api from '@/api'
export default {
  data () {
    return {
      loading: false,
      // 详情
      detail: {},
      requireRecommendList: []
    }
  },
  computed: {
    // 论文id
    paperId () {
      return this.$route.params.id
    },
    // query
    patentQuery () {
      return this.$route.query || {
        type: '',
        keywords: ''
      }
    }
  },
  created () {
    // 获取论文详情
    this.getJournalDetail()
    // 获取论文列表
    this.getJournalList()
  },
  methods: {
    // 获取论文详情
    getJournalDetail () {
      this.loading = true
      api.getJournalDetail(this.paperId).then(res => {
        if (res.data.code === 0) {
          console.log('论文详情', res.data.data)
          this.detail = res.data.data
        } else {
          this.$message.error(res.data.message)
        }
      }).catch((error) => {
        console.log(error)
        this.$message.error('请求出错')
      }).finally(() => {
        this.loading = false
      })
    },
    // 获取论文列表
    getJournalList () {
      this.loading = true
      api.getJournalList({
        pageNum: 1,
        pageSize: 10,
        ...this.patentQuery
      }).then(res => {
        if (res.data.code === 0) {
          console.log('论文列表', res.data.data)
          this.requireRecommendList = res.data.data.list
        } else {
          this.$message.error(res.data.message)
        }
      }).catch((error) => {
        console.log(error)
        this.$message.error('请求出错')
      }).finally(() => {
        this.loading = false
      })
    },
    // 进入论文详情页
    onDetail (item) {
      const { type, keywords } = this.patentQuery
      window.open(`/paper-detail/${item.id}?type=${type}&keywords=${keywords}`)
    }
  }
}
</script>

<style lang="scss" scoped>
  .resource_detail {
    display: flex;
    justify-content: space-between;
    width: 100%;
    .detail_left {
      flex: 1;
      margin-right: 20px;
      .base_info {
        .module_box_main {
          padding-bottom: 30px;
          .main_header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 67px;
            border-bottom: 1px solid #DCDFE6;
            .header_left {
              display: flex;
              height: 100%;
              align-items: center;
              & > h1 {
                font-size: 26px;
                font-weight: bold;
                line-height: 37px;
                color: #000000;
                margin-right: 30px;
              }
            }
          }
          .main_content {
            padding: 24px 0 0px 0;
            & > .description {
              font-size: 14px;
              font-weight: 500;
              line-height: 30px;
              color: #303133;
              margin-bottom: 22px;
            }
            .keywords {
              display: flex;
              font-size: 14px;
              line-height: 30px;
              margin-bottom: 10px;
              .keywords_label {
                width: 90px;
                color: #909399;
              }
              .keywords_value {
                color: #303133;
                & > span {
                  &+span {
                    margin-left: 10px;
                  }
                }
              }
            }
            .text_list {
              .text_item {
                display: flex;
                // max-width: 320px;
                font-size: 14px;
                line-height: 30px;
                margin-bottom: 10px;
                .item_label {
                  // flex: 0 0 90px;
                  width: 100px;
                  color: #909399;
                }
                .item_value {
                  // flex: 0 0 230px;
                  width: 220px;
                  color: #303133;
                }
              }
            }
          }
        }
      }
      .other_info {
        background: #fff url('../../../assets/images/detail/about_the_company.png') right bottom no-repeat;
        .module_box_main {
          padding-bottom: 30px;
          .text_item {
            display: flex;
            // max-width: 320px;
            font-size: 14px;
            line-height: 30px;
            margin-bottom: 10px;
            .item_label {
              // flex: 0 0 90px;
              width: 100px;
              color: #909399;
            }
            .item_value {
              // flex: 0 0 230px;
              width: 280px;
              color: #303133;
            }
          }
        }
      }
    }
    .detail_right {
      flex: 0 0 450px;
      .recommend_list {
        .module_box_main {
          padding-bottom: 20px;
          .recommend_item {
            display: block;
            padding-bottom: 10px;
            border-bottom: 1px dashed #DCDFE6;
            cursor: pointer;
            .item_header {
              display: flex;
              height: 50px;
              align-items: center;
              justify-content: space-between;
              & > h1 {
                width: 260px;
                font-size: 18px;
                font-weight: bold;
                line-height: 25px;
                color: #606266;
                padding-right: 20px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }
            }
            .item_footer {
              display: flex;
              justify-content: space-between;
              align-items: center;
              height: 30px;
              & > p {
                font-size: 14px;
                font-weight: 500;
                line-height: 20px;
                color: #909399;
                &:first-child {
                  width: 300px;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                }
                &:last-child {
                  width: 110px;
                  text-align: right;
                }
              }
            }
            &+.recommend_item {
              margin-top: 10px;
            }
          }
        }
        .module_box_footer {
          padding-bottom: 20px;
          .refresh_btn {
            font-size: 14px;
            font-weight: 500;
            line-height: 20px;
            color: #3473E6;
            cursor: pointer;
          }
        }
      }
    }

    .module_box {
      display: block;
      width: 100%;
      background-color: #fff;
      padding: 0 20px;
      box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.06);
      border-radius: 4px;
      overflow: hidden;
      .module_box_header {
        display: flex;
        align-items: center;
        height: 50px;
        margin-bottom: 10px;
        &::before {
          content: "";
          width: 3px;
          height: 20px;
          background: #3473E6;
          border-radius: 1px;
          margin-right: 10px;
        }
        & > p {
          font-size: 16px;
          font-weight: bold;
          line-height: 22px;
          color: #31394C;
        }
      }
      .module_box_main {
        padding: 0 10px;
      }
      .module_box_footer {
        padding: 0 10px;
      }
    }

    .tag {
      height: 24px;
      padding: 0 5px;
      background: rgba(52, 115, 230, 0);
      border: 1px solid #DCDFE6;
      border-radius: 2px;
      font-size: 12px;
      font-weight: bold;
      line-height: 24px;
      color: #3473E6;
      text-align: center;
      white-space: nowrap;
    }
  }
</style>
